<template>
  <div class="login login--admin">     
    <div class="login__content">
      <div class="login__header">
        <header class="main-header sticky-header-top" >
            <b-container fluid="xl">
                <b-row>
                    <b-col cols="12">                 
                        <b-navbar toggleable="md">
                            <router-link class="logo" :to="{path: '/'}">Luminor</router-link>
                            <div class="main-header__line"></div>                 
                        </b-navbar>
                    </b-col>
                </b-row>        
            </b-container>
        </header>
      </div>      
      <div class="login__main-content">
        <b-container fluid="xl">
          <b-row>
            <b-col cols="12" md="6" xl="4" offset-md="3" offset-xl="4" class="login__inner-content">
              
                <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"><span class="gold-lined-text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">Admin</span></h2> 
                <div class="login__lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">
                  Please, type your admin user's e-mail and password
                </div> 
                <validation-observer v-slot="{ handleSubmit }" tag="div" class="form-wrap">
                    <form @submit.prevent="handleSubmit(doLogin)">
                      <div>
                        <ValidationProvider rules="email|required" v-slot="{ failedRules }">
                          <b-form-group   
                            id="login-email"
                            label="E-mail"
                            label-for="email"  
                            class="animateScroll"    
                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                          >                                                        
                            <div class="form-input-wrap">
                                <b-form-input
                                    class="animateScroll"
                                    id="email"
                                    name="E-mail"
                                    v-model="loginForm.logInEmail"
                                    type="text"
                                    placeholder="Type your e-mail..."
                                    @focus="focusedParent($event)"
                                    @focusout="focusedOutParent($event)"
                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')"
                                    ></b-form-input>
                            </div>
                            <div class="error">{{getErrorMessageAdmin("emailadmin", Object.entries(failedRules)[0])}}</div>
                          </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider rules="required"  v-slot="{ failedRules }" vid="confirmation">                    
                          <b-form-group   
                            id="login-psw"
                            label="Password"
                            label-for="password"  
                            class="animateScroll"    
                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-5')"
                          >                                                        
                            <div class="form-input-wrap">
                                <b-form-input
                                    class="animateScroll"
                                    id="password"
                                    v-model="loginForm.psw"
                                    type="password"
                                    placeholder="Type your password..."
                                    @focus="focusedParent($event)"
                                    @focusout="focusedOutParent($event)"
                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-6')"
                                    ></b-form-input>
                            </div>
                            <div class="error">{{getErrorMessageAdmin("pswadmin", Object.entries(failedRules)[0])}}</div>
                          </b-form-group>
                        </ValidationProvider>           
                      </div>           
                      <div class="error" style="margin-bottom: 30px">{{ error }}</div>
                      <div>
                        <button type="submit" class="btn btn--black btn--arrow"><span>Login</span></button>
                      </div>
                    </form>
                </validation-observer>
              
            </b-col>
          </b-row>
        </b-container>        
      </div>
    </div>
  </div>  
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI"; 

import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import errorMessages from "@/assets/errorMessages.json";
import {setInteractionMode} from 'vee-validate';
import {required, email, confirmed} from "vee-validate/dist/rules";

setInteractionMode('eager');
extend('required', {
  ...required,
  message: "This field is required."
});
extend("email", {
  ...email,
  message: "Type only valid email",
});
extend("confirmed", {
  ...confirmed,
  message: "Type the same password",
});

export default {
  data() {
    return {
      loginForm: {
        logInEmail: undefined,
        psw: undefined,        
      },
      error: ''
    }
  },
    components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [apiHandlerMI],  
  methods: {
    getErrorMessageAdmin(fieldName, violation) {
      // console.log('getErrorMessage-admin', fieldName, violation, errorMessages)
      
      return violation
          ? (errorMessages[fieldName] &&
          errorMessages[fieldName][violation[0]]['hu']) ??
          violation[1]       
          : undefined;
    },

    doLogin() {
      const _this = this;
      this.post('login', 
        {
            email: _this.loginForm.logInEmail, 
            password: _this.loginForm.psw
        }, 
        {},
        function (response) {
            if (response && response.data.user.user_type === _this.CONFIG.adminType) {            
              localStorage.setItem(_this.CONFIG.localStorageNameAdmin, response.data.token)
              _this.$store.state.userData = response.data.user;
              _this.$store.state.token = response.data.token;                                        
              _this.$router.push( {name: 'adminPage'});           
            } else {
                _this.error = 'Wrong administrator user name or password'
            }
          }, function (error) {
            console.log('error')
            if (error.data.error === 'Not found!') {
              _this.error = 'Wrong user name or password'
            }
          }
      )
    },
     focusedParent(event){                
            if (event) {                
                let focusedElement = event.target
                focusedElement.classList.add('focused')        
                focusedElement.parentNode.classList.add('focused')                
            }            
        },
        focusedOutParent(event){
            if (event) {                
                let focusedElement = event.target
                focusedElement.classList.remove('focused')        
                focusedElement.parentNode.classList.remove('focused')                
            }
        },
  }
}

</script>

